// src/components/PrintLoadList.js
import React, { forwardRef } from 'react';
import './PrintLoadList.css';

const PrintLoadList = forwardRef(({ sales }, ref) => {
  const uniqueCustomers = [...new Set(sales.map(sale => sale.customer))];

  return ( 
    <div className='print-container' ref={ref}>
      {uniqueCustomers.map((customer, idx) => {
        const customerSales = sales.filter(sale => sale.customer === customer);
        const totalQty = customerSales.reduce((total, sale) => total + sale.saleqty, 0);
        const date = [...new Set(sales.map(sale => sale.createdAt))];

        return (
          <div key={idx} className="print-section">
            <h5 className='text-center fw-bold'>LOAD LIST</h5>
            <h5 className='text-center'>Customer: {customer}</h5>
            <h6 className="text-center fw-bold">DATE: {date}</h6>
            <table className="table table-bordered excel-table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Label</th>
                  <th>QTY</th>
                </tr>
              </thead>
              <tbody>
                {customerSales.map((sale, index) => (
                  <tr key={index}>
                    <td>{sale.itemid.item}</td>
                    <td>{sale.itemid.label}</td>
                    <td>{sale.saleqty}</td>
                  </tr>
                ))}
              </tbody>
              <thead>
                <tr>
                  <td colSpan="2"><strong>Total Qty</strong></td>
                  <td><strong>{totalQty}</strong></td>
                </tr>
              </thead>
            </table>
          </div>
        );
      })}
    </div>
  );
});

export default PrintLoadList;
