import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllItemsMaster, creatingSale, fetchAllCustomers, createWholitemsforghost, createliveSale, deleteLivesales, fetchAlllivesales, deletesingleLivesalefromdb, handleRetailPricechange, handleRetailQTYchange } from '../../api/api';

export default function Sales() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({});
  const [customers, setCustomers] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');

  const [selectedCustomer, setSelectedCustomer] = useState('');
  

  const handleCustomerChange = (event) => {
    const customerName = event.target.value;
    setSelectedCustomer(customerName);
    // Redirect to wholesalepercustomer page with the selected customer as a query parameter
    if (customerName) {
      navigate(`/wholesalepercustomer?customer=${customerName}`);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const items = await fetchAllItemsMaster();
      const customers = await fetchAllCustomers();
  
      // Filter items to include only those where 'code' is not empty
      const filteredItems = items.filter(item => item.code && item.code.trim() !== '');
  
      setCustomers(customers);
      console.log(filteredItems, customers);
      setItems(filteredItems);
  
      // Initialize formData with default values for sale quantity
      const initialFormData = {};
      filteredItems.forEach(item => {
        initialFormData[`sellqty_${item.id}`] = 0;
        initialFormData[`sellprice_${item.id}`] = item.saleprice || 0;
        initialFormData[`total_${item.id}`] = 0;
      });
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };  

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };  

  const addtosale = async (itemId) => {
    const fieldName = `sellqty_${itemId}`;
    const currentQty = parseFloat(formData[fieldName] || 0);
    const newQty = currentQty + 1;
  
    // Fetch the updated item data
    const updatedItem = {
      id: itemId,
      sellqty: newQty,
      sellprice: parseFloat(formData[`sellprice_${itemId}`] || 0),
      total: (newQty * parseFloat(formData[`sellprice_${itemId}`] || 0)).toFixed(2),
    };
  
    // Fetch username from localStorage
    const username = localStorage.getItem('username');
  
    if (username) {
      const dataToSubmit = {
        username,
        items: [updatedItem], // Submit only the updated item
      };
  
      // Make an API call to submit the updated data
      try {
        const response = await createliveSale(dataToSubmit);
        console.log('API Response:', response);
      } catch (error) {
        console.error('Error submitting live sale:', error);
      }
    } else {
      console.error('Username not found in localStorage');
    }
  };

  //  to clear the display local storage 
  const handleClearFilteredItems = async () => {
    const username = localStorage.getItem("username"); // Fetch username from local storage

    if (!username) {
      toast.error("Username not found in local storage.");
      return;
    }

    const isConfirmed = window.confirm("Are you sure you want to clear livesales?");
    if (isConfirmed) {
      try {
        await deleteLivesales(username); // Pass the username instead of noteId
        toast.success("Sales deleted successfully.");
        window.location.reload(); // This will reload the current page
      } catch (error) {
        console.log("this is eror" ,error)
        // Check for the specific error message
        if (error !== "Sale not found for deleting") {
          toast.info("No sales found for deleting.");
        } else {
          toast.error("Error deleting live sales. Please try again.");
        }
      }
    }
  };

  const handleGhostCLient = async () => {
    try {
        const constantData = {
          itemName: 'Default Item',
          itemPrice: 1,
          itemCategory: 'Wholesale',
        };
        // Submit the API request (modify the URL and data as needed)
        await createWholitemsforghost(constantData);
        // Once the API request is successful, navigate to the /wholesale page
        navigate('/wholesale');
    } catch (error) {
        // Handle any errors from the API call
        console.error('Error submitting the API request', error);
    }
};


  // Function to handle container selection
  const handleLabelSelect = (label) => {
    setSelectedLabel(label);
  };
  // for search 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const uniqueLabels = [...new Set(items.map(item => item.label))];

  // maintains original order 
  const filteredItems = items
  .filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (!selectedLabel || item.label === selectedLabel)
  )
  .map(item => ({
    ...item,
    sellqty: parseFloat(formData[`sellqty_${item.id}`]) || 0, // Add sellqty for further processing if needed
  }));

  // maintains original order 
  const filteredItemsForDisplayitesmatTop = items.map(item => ({
    ...item,
    sellqty: parseFloat(formData[`sellqty_${item.id}`]) || 0,
  }));
  


  // for live Sales, data similar to display live sales page *********************************************** 
    const [itemsMaster, setItemsMaster] = useState([]); // Store master items
    const [liveItems, setLiveItems] = useState([]);
    const [joinedItems, setJoinedItems] = useState([]); // Store joined data
    const username = localStorage.getItem('username'); // Retrieve username from local storage
  
    useEffect(() => {
      const fetchData = async () => {
        await fetchItemsMaster();
        await fetchLiveItems();
      };
  
      fetchData();
    }, []);
  
    useEffect(() => {
      // Fetch master items once at the beginning
      const fetchInitialData = async () => {
        await fetchItemsMaster();
      };
      fetchInitialData();
  
      // Set up interval to fetch live items every 2 seconds
      const intervalId = setInterval(() => {
        fetchLiveItems();
      }, 2000);
  
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }, []);
  
    const fetchLiveItems = async () => {
      try {
        // const itemsbeforefilter = await fetchAlllivesales();
        // // Filter items where the user matches the username
        // const items = itemsbeforefilter.filter(item => item.user === username && item.saleqty > 0);

        const itemsbeforefilter = await fetchAlllivesales();

        // Filter items where the user matches the username and saleqty > 0
        const items = itemsbeforefilter
          .filter(item => item.user === username && item.saleqty > 0)
          .sort((a, b) => a.id - b.id); // Sort in ascending order by id

    
        // Aggregate items by the 'item' field
        const aggregatedItems = items.reduce((acc, curr) => {
          const existingItem = acc.find((item) => item.item === curr.item);
          if (existingItem) {
            // Combine or aggregate fields as needed
            existingItem.saleqty += curr.saleqty; // Example of summing a 'quantity' field
          } else {
            acc.push({ ...curr }); // Add a new item if it doesn't exist
          }
          return acc;
        }, []);
    
        console.log('Aggregated Live Items:', aggregatedItems);
        setLiveItems(aggregatedItems);
      } catch (error) {
        console.error('Error fetching live items:', error);
      }
    };
    
  
    const fetchItemsMaster = async () => {
      try {
        const response = await fetchAllItemsMaster();
        console.log('Master Items:', response);
        setItemsMaster(response || []);
      } catch (error) {
        console.error('Error fetching master items:', error);
      }
    };
  
    useEffect(() => {
      // Perform the join when either liveItems or itemsMaster changes
      const joinData = () => {
        const joined = liveItems.map(liveItem => {
          const masterItem = itemsMaster.find(master => master.id == liveItem.item);
          return {
            ...liveItem,
            masterItemName: masterItem ? masterItem.item : 'Unnamed Item',
            label: masterItem.label,
            masterid: masterItem.id
          };
        });
        console.log('Joined Items:', joined);
        setJoinedItems(joined);
      };
  
      if (liveItems.length && itemsMaster.length) {
        joinData();
      }
    }, [liveItems, itemsMaster]);
  
    // Calculate totals
    const totalQty = joinedItems.reduce((sum, item) => sum + parseFloat(item.saleqty || 0), 0);
    const totalPrice = joinedItems.reduce((sum, item) => sum + parseFloat(item.saleprice || 0), 0);
    const totalAmount = joinedItems.reduce((sum, item) => sum + parseFloat(item.saleqty * item.saleprice || 0), 0);

  const deletesingleLivesale = async (id, ite) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this");
    if (isConfirmed) {
      console.log(id)
      try {
        // Delete the item from the database
        await deletesingleLivesalefromdb(id);
  
        // Get filtered items from local storage
        const filteredItems = JSON.parse(localStorage.getItem("filteredItems")) || [];
  
        // Filter out the item to be deleted
        const updatedFilteredItems = filteredItems.filter(item => item.id !== ite);
  
        // Update local storage with the filtered items
        localStorage.setItem("filteredItems", JSON.stringify(updatedFilteredItems));
  
        // Fetch updated data from the database
        fetchItemsMaster();
        fetchLiveItems();
        // Show success toast
        toast.success('Item deleted successfully.');
      } catch (error) {
        // Show error toast in case of failure
        toast.error('Error deleting. Please try again.');
      }
    }
  };

  const qtyRef = useRef();
  const priceRef = useRef();

  // Handle updates directly in DOM for saleqty and saleprice
  const handleQtyChange = async (id, updatedQty) => {
    try {
      await handleRetailQTYchange(updatedQty, id)
      fetchLiveItems()
    } catch (error) {
      console.log(error)
    }
  };

  const handlePriceChange = async (id, updatedPrice) => {
    try {
      await handleRetailPricechange(updatedPrice, id)
      fetchLiveItems()
    } catch (error) {
      console.log(error)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare itemsData object with updated sell prices, sale quantities, and labels
      const itemsData = [];
  
      // Get the selected sale type and customer
      const saleType = e.target.elements.saletype.value;
      const customer = e.target.elements.customer.value;

      const username = localStorage.getItem('username');  // Assuming 'username' is stored in localStorage
  
      const fetchedItems = joinedItems.filter(item => item.user === username);
  
      if (!fetchedItems || fetchedItems.length === 0) {
        toast.error('No items found for the selected sale type and customer');
        return;
      }
  
      // Now use the fetched items (which is actually joinedItems in this case)
      for (const item of fetchedItems) {
        // For each item, ensure we are adding the right sell price and quantity (use default or updated values)
        const sellPrice = item.saleprice || 0; // Use default or fetched sell price
        const sellQty = item.saleqty || 0; // Use default or fetched sell quantity
  
        const label = item.label || 'Unknown';
        
        // Add the item data to itemsData
        itemsData.push({
          itemId: item.masterid,
          itemName: item.masterItemName,
          seller: item.user,
          sellPrice,
          sellQty,
          total: (sellQty * sellPrice).toFixed(2),
          label,
          saleType,
          customer,
          code: item.code
        });
      }
  
      // Now you can proceed to submit the `itemsData` to your API or handle the next steps
      // console.log('Items to submit:', itemsData);
  
      // For example, submitting the data (replace with your API call)
      const response = await creatingSale(itemsData);  // Assuming creatingSale takes the itemsData array
      // console.log('Sale created:', response);
      toast.success('Sale created successfully.');
      
    } catch (error) {
      console.error('Error submitting sale:', error);
      toast.error('Error creating sale. Please try again.');
    }
  }; 

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container-fluid  my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
            <div>
              <div className='mt-5'>

              <div className='mt-3'>
              <Link to='/invoices'>
                <button className='btn btn-primary btn-sm float-end mx-2' >Invoices</button>
              </Link>

              <Link to='/wholesale'>
                <button className='btn btn-warning btn-sm float-end' onClick={handleGhostCLient} >Switch to W.S</button>
              </Link>

              <Link
                to="/displaypage"
                state={{ filteredItemsForDisplayitesmatTop }}
              >
                <button className='btn btn-sm btn-info'>Display</button>
              </Link>

              <button className='btn btn-sm btn-dark mx-2' onClick={handleClearFilteredItems}>Clear</button>

              {/* <select
                name="customer"
                required
                className="form-control w-25 mx-2"
                value={selectedCustomer}
                onChange={handleCustomerChange}
              >
                <option value="">Select customer for LIVE.WH</option>
                {customers.map((customer) => (
                  <option key={customer.id} value={customer.name}>
                    {customer.name}
                  </option>
                ))}
              </select> */}

              <select
                name="customer"
                required
                className="form-control w-25 mx-2"
                value={selectedCustomer}
                onChange={handleCustomerChange}
              >
                <option value="">Select customer for LIVE.WH</option>
                {customers
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
                  .map((customer) => (
                    <option key={customer.id} value={customer.name}>
                      {customer.name}
                    </option>
                  ))}
              </select>


              <hr/>

              <select
                className='form-control mx-1 float-end w-25'
                value={selectedLabel}
                onChange={(e) => handleLabelSelect(e.target.value)}
              >
                <option value="">All Labels</option>
                {uniqueLabels.map((label, index) => (
                  <option key={index} value={label}>{label}</option>
                ))}
              </select>

              <input
                  className='form-control float-end w-25'
                  type="text"
                  placeholder="Search items..."
                  value={searchQuery}
                  onChange={handleSearchChange}
              />

              <h5><u className='titilescolor'>Retail Sales page</u></h5>
              </div>

              {/* <form onSubmit={handleSubmit}> */}

              <form onSubmit={handleSubmit}>
                <div className='d-flex mt-5'>
                  <select class="form-select w-25" name='saletype' required aria-label="Default select example">
                    <option selected value="retail">Retail</option>
                    <option value="wholesale">Wholesale</option>
                  </select>
                
                  {/* <input type="text" name='customer' required class="form-control w-25 mx-2" id="customer" placeholder='Enter customer name' /> */}
                  {/* <select name="customer" required className="form-control w-25 mx-2">
                    <option value="">Select customer</option>
                    {customers.map((customer) => (
                      <option key={customer.id} value={customer.name}>
                        {customer.name}
                      </option>
                    ))}
                  </select> */}

                  <select name="customer" required className="form-control w-25 mx-2">
                    <option value="">Select customer</option>
                    {customers
                      .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
                      .map((customer) => (
                        <option key={customer.id} value={customer.name}>
                          {customer.name}
                        </option>
                      ))}
                  </select>

                  <button className='btn btn-sm btn-success'>Sell</button>
                </div>
              </form>
              <hr />

              <div className='row'>
                  <div className='col-md-6'>
                  <div className='mt-4' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    <table className="table table-striped">
                      <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <tr className='theads'>
                          <td><strong></strong></td>
                          <th>Item</th>
                          <th>QTY</th>
                          <th>Label</th>
                          <th>Price</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className='resizetable'>
                        {filteredItems.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.item}</td>
                            <td>{item.masterqty}</td>
                            <td>{item.label}</td>
                            <td className='text-primary'>{item.saleprice}</td>
                            <td>
                              <button
                                className='btn btn-sm btn-success'
                                onClick={() => addtosale(item.id)}
                              >
                                <i className="fa-solid fa-plus"></i>
                              </button>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </div>

                  <div className='col-md-6'>
                  <div className='mt-4' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                  <table className="table table-striped" border="1">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-success">Item</th>
                        <th className="text-success">Label</th>
                        <th className="text-success">QTY</th>
                        <th className="text-success">Price</th>
                        <th className="text-success">Total</th>
                      </tr>
                    </thead>
                    <tbody className='resizetable'>
                      {joinedItems.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}.</td>
                          <td>
                            {item.masterItemName.includes('#1')
                              ? item.masterItemName.replace('#1', '(C)')
                              : item.masterItemName.includes('#2')
                              ? item.masterItemName.replace('#2', '(P)')
                              : item.masterItemName.includes('#3')
                              ? item.masterItemName.replace('#3', '(WL)')
                              : item.masterItemName}
                          </td>
                          <td>{item.label}</td>
                          
                          <td style={{ width: "60px" }}>
                          <input
                              type="number"
                              min="0"
                              className="form-control"
                              name={`saleqty${item.id}`}
                              defaultValue={item.saleqty}
                              onFocus={(e) => e.target.select()}
                              onChange={(e) => handleQtyChange(item.id, e.target.value)} // Pass value directly from input
                            />
                          </td>
                          <td style={{ width: "90px" }}>
                            <input
                              ref={priceRef}
                              type="number"
                              min="0"
                              className="form-control"
                              name={`saleprice${item.id}`}
                              defaultValue={item.saleprice} // Use defaultValue to set initial value
                              onFocus={(e) => e.target.select()}
                              onChange={(e) => handlePriceChange(item.id, e.target.value)} // Pass item.id to the handler
                            />
                          </td>
                          <td>{item.saleqty * item.saleprice}</td>
                          <td>
                            <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => deletesingleLivesale(item.id, item.item)} style={{ paddingLeft: '10px'}}></i>
                          </td>
                          
                        </tr>
                      ))}
                    </tbody>
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-success">Total</th>
                        <th className="text-success"></th>
                        <th className="text-success">{totalQty.toLocaleString()}</th>
                        <th className="text-success">{totalPrice.toLocaleString()}</th>
                        <th className="text-danger">{totalAmount.toLocaleString()}</th>
                      </tr>
                    </thead>
                  </table>
                    </div>
                  </div>
              </div>

              {/* </form> */}
              <ToastContainer />
            </div>

            </div>
          }       
          
          {/* other code starts here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
