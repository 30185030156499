import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSalesW } from '../../api/api';

export default function Customerpendingorders() {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [sales, setSales] = useState([]);

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      const salesData = await fetchAllSalesW();
      const approvedSales = salesData.filter(sale => sale.customer === localStorage.getItem('name'));
      setSales(approvedSales);

      console.log(approvedSales);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  // Calculate totals for saleqty and saleprice
  const totalQty = sales.reduce((sum, sale) => sum + sale.saleqty, 0);
  const totalPrice = sales.reduce((sum, sale) => sum + sale.saleprice * sale.saleqty, 0);

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-5'>
            <Link to={`/homepage`}>
              <button className='btn btn-sm btn-dark float-end'>Back</button>
            </Link>
            <h5><u className='titilescolor'>Pending Orders</u></h5>

            <table className="table table-striped mt-2">
              <thead>
                <tr className='theads'>
                  <th>Sale QTY</th>
                  <th>Sale Price</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {sales.length < 1 ? (
                  <tr>
                    <td colSpan="5">
                      <h5>There are no orders</h5>
                    </td>
                  </tr>
                ) : (
                  sales.map((sale, index) => (
                    <tr key={sale.saleid}>
                      <td>{sale.saleqty}</td>
                      <td>{(sale.saleprice * sale.saleqty).toFixed(2)}</td>
                      <td>{sale.createdAt}</td>
                    </tr>
                  ))
                )}
                {/* Total row */}
                {sales.length > 0 && (
                  <tr className='table-primary'>
                    <td><strong>Total</strong></td>
                    <td><strong>{totalQty}</strong></td>
                    <td><strong>{totalPrice.toFixed(2)}</strong></td>
                  </tr>
                )}
              </tbody>
            </table>
            <ToastContainer />
          </div>
          }
          
          {/* others begin here */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
